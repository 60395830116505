import React from 'react';

import RustReportV2Ad from '../RustReportV2Ad';

const RustReportSep24 = () => {
    const NUM_JOBS = 867
    const NUM_COMPANIES = 109
    const LARGE_HIRERS = [
        ['Amazon', 211],
        ['IBM', 64],
        ['Microsoft', 50],
        ['Apple', 42],
        ['Cloudflare', 40],
        ['DataDog', 37],
        ['Canonical', 27],
        ['Tesla', 20],
        ['xAI', 19],
        ['SpaceX', 15],
    ]
    const OTHER_HIRERS = [
        ['GitHub', 13],
        ['Discord', 11],
        ['Fastly', 11],
        ['Worldcoin', 11],
        ['Fortanix', 9],
        ['Crypto.com', 9],
        ['Figma', 9],
        ['Google ', 8],
        ['IOHK', 7],
        ['Deliveroo', 7],
        ['1Password', 7],
        ['HyperExponential', 7],
        ['Disney', 7],
        ['Activision', 6],
        ['Ardan Labs', 6],
        ['Mozilla', 6],
        ['Parity', 6],
        ['Sentry', 6],
        ['Syndica', 6],
        ['KeyRock', 6],
        ['Matic', 6],
        ['Data Direct Networks', 6],
        ['Volume Finance', 5],
        ['Conduit', 5],
        ['informal', 5],
        ['Threema', 5],
        ['Deep Fence', 5],
        ['HelsingAI', 5],
        ['OpenAI', 5],
        ['Hugging Face', 4],
        ['OneSignal', 4],
        ['StarkWare', 4],
        ['Scaleway', 4],
        ['Dropbox', 4],
        ['Ellipsis Labs', 4],
        ['Mercedes', 4],
        ['Heliax', 4],
        ['Stockly', 3],
        ['Axiom', 3],
        ['InfinyOn', 3],
        ['AllSpice', 3],
        ['HealPay', 3],
        ['Open Cosmos', 3],
        ['Chorus One', 3],
        ['InfluxDB', 3],
        ['ApolloGraphQL', 3],
        ['Vivint', 3],
        ['Akamai', 3],
        ['Swift Navigation', 3],
        ['Qumulo', 3],
        ['Svix', 3],
        ['10X Genomics', 2],
        ['Aztec', 2],
        ['Zoo', 2],
        ['Matter Labs', 2],
        ['Osmosis Labs', 2],
        ['amo', 2],
        ['Zama', 2],
        ['OpenTalk', 2],
        ['Fullstory', 2],
        ['Qovery', 2],
        ['Qdrant', 2],
        ['Embark', 2],
        ['Railway', 2],
        ['Smarkets', 2],
        ['arm', 2],
        ['Star Lab', 1],
        ['ZORA', 1],
        ['Uniswap Labs', 1],
        ['OVH', 1],
        ['Ledger', 1],
        ['Shadow', 1],
        ['Clever Cloud', 1],
        ['Rapid7', 1],
        ['Zed Industries', 1],
        ['Atlassian', 1],
        ['Two Sigma', 1],
        ['Tonari', 1],
        ['Immunant', 1],
        ['Prisma', 1],
        ['Volka', 1],
        ['Toyota Connected', 1],
        ['KION', 1],
        ['PingCAP', 1],
        ['Liquid Analytics', 1],
        ['Meilisearch', 1],
        ['DSCVR', 1],
        ['Ditto', 1],
        ['Astropad', 1],
        ['Tabnine', 1],
        ['AppSignal', 1],
        ['Scythe Robotics', 1],
        ['FP Complete', 1],
        ['Oxide', 1],
        ['Grafbase', 1],
        ['Zelis', 1],
        ['shuttle', 1],
        ['Qwiet AI', 1],
        ['LINE', 1],
    ]
    const LARGE_INDUSTRIES = [
        ['cloud/infrastructure', 285],
        ['crypto', 85],
        ['systems/hardware', 76],
        ['productivity', 76],
        ['consultancy', 72],
        ['data science', 50],
        ['monitoring/reliability', 44],
        ['iot/robotics/automotive', 36],
        ['security', 33],
        ['messaging/notifications', 23],
    ]
    const OTHER_INDUSTRIES = [
        ['aerospace', 18],
        ['browser', 14],
        ['fintech', 12],
        ['marketplace', 10],
        ['gaming', 9],
        ['databases', 8],
        ['streaming', 7],
        ['networking', 3],
        ['health/biotech', 3],
        ['social media', 2],
        ['animation', 1],
    ]
    const LEVELS = [
        ['junior', 26],
        ['mid', 480],
        ['senior', 361],
    ]
    return <RustReportV2Ad
        title={'Rust Jobs Report: September 2024'}
        subtitle={'Welcome to the September 2024 Rust Jobs Report by filtra. Please enjoy our overview of the Rust job market.'}
        titleNote={'To add your or another company to the filtra Rust jobs index, please email filtra@filtra.io with the subject line "Rust Index"'}
        openPositions={NUM_JOBS}
        openPositionsDesc={'We found the highest number of Rust job listings ever this month, 867.'}
        numCompanies={NUM_COMPANIES}
        numCompaniesDesc={'The total number of hirers shrank slightly this month. So, each individual company is hiring more.'}
        largeHirers={LARGE_HIRERS}
        largeHirersDesc={'The leaderboard for hirers had some interesting developments in September. Amazon had a staggering number of Rust jobs posted. IBM took the number two spot from Microsoft. And, if "Elon" were a category, he\'d be our number three contender.'}
        otherHirers={OTHER_HIRERS}
        otherHirersDesc={"Our list of other hirers continues to get deeper. You'll notice that GitHub, Discord, Fastly, and Worldcoin all have 10+ postings. By way of newcomers, this month saw Disney joining the index. They seem to mostly use Rust for their streaming platforms. If you know of a company that should be on this list but isn't, let us know: filtra@filtra.io."}
        topTenIndustries={LARGE_INDUSTRIES}
        topTenIndustriesDesc={'The list of top industries is much the same as it was last month.'}
        otherIndustries={OTHER_INDUSTRIES}
        otherIndustriesDesc={'Rust is pretty much everywhere!'}
        seniority={LEVELS}
        seniorityDesc={"September was actually a pretty good month for entry-level positions. We saw twice as many posted this month compared to last month. Many of those positions were internships."}
    />
}

export default RustReportSep24